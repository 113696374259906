.quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 20px;
  font-size: 16px;
  .leftCol,
  .rightCol {
    flex: 1;
    border: 1px solid #ccc;
    font-weight: bold;
    padding: 10px 0;
    border-bottom: none;
  }

  .leftCol {
    border-left: none;
    border-right: none;
  }
  .rightCol {
    border-right: none;
  }
}

