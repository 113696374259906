.quantity-vaccine {
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin: 10px 0 20px;
  font-size: 16px;
  .leftCol,
  .rightCol {
    border: 1px solid #ccc;
    font-weight: bold;
    padding: 10px;
    border-bottom: none;
    flex: 1;
    .title {
      @media screen and (max-width: 1600px) {
        min-height: 50px;
      }
    }
  }

  .leftCol {
    border-left: none;
    border-right: none;
    padding-left: 10px;
  }
  .rightCol {
    border-right: none;
    padding-right: 10px;
  }
}

