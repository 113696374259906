@import "./variables.less";
@import "./common.less";
@import "./login.less";
@import "./capacity-management.less";
@import "./vaccine.less";
@import "./user-management.less";

.ant-input,
.ant-picker-input > input {
  border-radius: 0;
}

.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  width: 5px;
  background-color: rgb(81, 209, 196);
  left: 0px;
  border-right: none;
  border-radius: 0px 50% 50% 0px;
}

.ant-menu-item:hover,
.ant-menu-item-selected {
  color: rgb(67, 67, 67);
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
  cursor: pointer;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 15px;
}

.ant-input[disabled] {
  color: rgba(0,0,0,.65);
}

.ant-picker-input>input[disabled] {
  color: rgba(0,0,0,.65);
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0,0,0,.65);	
}

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: rgba(0,0,0,.65);	
}
