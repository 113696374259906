.progress-item {
  text-align: left;
  margin: 10px 0 5px;
  .ant-progress-text {
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 0;
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-progress-outer {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
}
.legend {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  &-item {
    display: flex;
    align-items: center;

    &.completed {
      margin-right: 15px;
      .point {
        background: #51d1c4;
      }
    }
    &.rejected {
      .point {
        background: #ff6565;
      }
    }
    .point {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
    .text {
      font-weight: 600;
    }
  }
}

