.ant-card-head-wrapper {
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .ant-card-extra {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
      }
}
