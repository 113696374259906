// .table-custom {
//   table {
//     thead th {
//       font-weight: bold;
//       line-height: 1.4;
//       background-color: @primary-color;
//       color: #fff;
//     }
//     tbody {
//       tr {
//         &:nth-child(even) {
//           background-color: #1890ff2e;
//         }
//         &:hover {
//           td {
//             background: unset !important;
//           }
//         }
//       }
//     }
//   }
// }

.ant-input-search {
  input {
    border-radius: 15px 0px 0px 15px;
  }
  input:focus {
    box-shadow: 0px 0px 5px #7aded1;

    + .ant-input-group-addon {
      button {
        border: 1px solid #7aded1;
        box-shadow: 0px 0px 5px #7aded1;
        background-color: #7aded1;
        svg {
          fill: #fff;
        }
      }
    }
  }
}

.ant-form-item-control-input {
  input {
    border-radius: 15px;
  }
}

.border-table {
  .ant-table-thead > tr > th {
    border: 1px solid #f0f0f0;
  }
  .ant-table-tbody > tr > td {
    border: 1px solid #f0f0f0;
  }
}

.summaries-table {
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tfoot
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tfoot
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tfoot
    > tr
    > td {
    border-right: 1px solid #d9d9d9;
    &.not-last-unit {
      border-right: 1px solid #f0f0f0;
    }
    &.last-department {
      border-right: 1px solid #f0f0f0;
    }
  }

  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tfoot
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tfoot
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tfoot
    > tr
    > td {
    &:last-child {
      border-right: 1px solid #f0f0f0;
    }
  }

  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr:not(:last-child)
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr:not(:last-child)
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > thead
    > tr:not(:last-child)
    > th {
    &.command-header {
      border-bottom: 1px solid #d9d9d9;
      white-space: nowrap;
    }
  }
  .ant-table-tbody {
    tr {
      td:not(:first-child) {
        white-space: nowrap;
      }
    }
  }
}

.no-margin {
  .ant-table {
    margin: 0 !important;
  }
}