.card-no-pd {
  .ant-card {
    &-head {
      border-bottom: none;
      &-wrapper{
        text-overflow: ellipsis;
        overflow: hidden;
      }
      @media only screen and (max-width: 900px) {
        &-title {
          font-size: 12px;
        }
      }
      @media only screen and (max-width: 700px) {
        &-title {
          font-size: 11px;
        }
      }
    }
    &-body {
      padding-top: 0;
    }
  }
}
.ant-col {
  .ant-result{
    padding: 0px;
  }
}

