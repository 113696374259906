.mini-box {
  display: inline-block;
  padding: 5px;
  width: 100%;
  margin: 5px;
  border-radius: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
}

.gutter-box {
  padding: 8px 0;
}

