.statistic-custom {
  &.ant-statistic {
    border-radius: 15px;
    padding: 10px 20px;
    margin: 10px 10px !important;
    width: 16em;
    margin-left: 0;
    background: linear-gradient(270deg, #ffa940 0%, #fa8c16 100%);
    box-shadow: rgba(250, 140, 22, 0.61) 1px 2px 6px;

    > * {
      color: #fff;
      font-family: "Montserrat", sans-serif;
    }
    .ant-statistic-content-value-int {
      font-size: 1.6em;
      font-weight: bold;
    }
    .ant-statistic-title {
      font-weight: bold;
    }
    .ant-statistic-content-suffix {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 567px) {
  .statistic-custom {
    &.ant-statistic {
      width: 100%;
      min-width: 200px;
    }}
}

@media screen and (max-width: 1000px) {
  .custom-timeslots {
    flex-direction: column !important;
  }
}

