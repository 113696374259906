.vaccine {
  &_inventory {
    &_title {
      &_right {
        text-align: end;
        @media screen and (max-width: 768px) {
          margin: 10px 0 0 0;
          text-align: start;
        }
      }
    }
  }
}