.time-slot-item-container {
    position: relative;
}


.time-slot-edit-button {
    position: absolute !important;
    top: 0;
    right: 0;
}

.time-slot-delete-button {
    position: absolute !important;
    top: 100;
    right: 0;
}

.time-slot-item {
    .ant-statistic-content {
        background-color: transparent;
        color: #C6C6C6;
        box-shadow:2px 3px 10px rgba(0, 0, 0, 0.22);
        border: 1px solid rgb(191 191 191 / 0%);
        width: 160px;
        display: flex;
        justify-content: center;
        padding: 5px;
        margin: 10px;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.5px;
    }
  
    &.active {
        .ant-statistic-content {
            background-color: #51d1c4;
            color: #fff;
            box-shadow: 1px 2px 6px #51d1c4ab;
            border: 1px solid #51d1c4;
        }
}
}
