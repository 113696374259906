.custom-datapicker {
    input {
        text-transform: uppercase;
    }
    input[disabled] {
      color: rgba(0,0,0,.65);
    }
}


