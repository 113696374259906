.loginWrapper {
  height: 100vh;
  text-align: center;
  background-color: #1890ff;
  .login-form {
    background: #fff;
    padding: 2rem;
    width: 500px;
    margin: 5rem auto;
    border-radius: 15px;
    border: 1px solid #f5f5f5;
  }
  .logo {
    width: 100px;
    height: 100px;
    margin: auto;
    img {
      max-width: 100%;
    }
  }
  .header {
    color: @text-color-secondary;
    display: block;
    text-decoration: none;
  }
}

.login-wrapper {
  min-height: 100vh;
  width: 100vw;
  &_left {
    background-color: #f1d8b4;
    width: 60%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    &_content {
      position: absolute;
      text-align: center;
      bottom: 0;
      display: flex;
      flex-direction: column;
      top: 0;
      overflow: hidden;
      &_logo {
        width: 100px;
        height: 100px;
        flex: 1;
        margin: 5% 0 0 5%
      }
      &_title {
        flex: 1;
        &-TITLE {
          font-size: 3rem;
          margin-bottom: 10px;
          @media screen and (max-width: 768px) {
            font-size: 28px !important;
          }
        }
        &-TEXT {
          font-size: 20px;
          @media screen and (max-width: 768px) {
            font-size: 18px !important;
          }
        }
        &-BUTTON {
          margin-top: 30px;
          @media screen and (min-width: 769px) {
            display: none;
          }
        }
      }
      &_background {
        flex: 1;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &_right {
    width: 40%;
    background-color: #fafafa;
    position: relative;
    text-align: center;
    @media screen and (max-width: 768px) {
      display: none;
    }
    &_button {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

