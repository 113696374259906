.personnel-table {
  // custom antd
  .ant-checkbox-inner {
    border-radius: 2px;
  }
  .anticon {
    vertical-align: baseline;
  }
}

.row-grey-background {
  background-color: #ffd6d5;
}

