.widget {
  &-wrap {
    background-color: #ffffff;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 15px;
    box-shadow: 0px 1px 6px rgba(33, 33, 33, 0.38);
    -webkit-box-shadow: 0px 1px 6px rgba(33, 33, 33, 0.38);
    -moz-box-shadow: 0px 1px 6px rgba(33, 33, 33, 0.38);
    -o-box-shadow: 0px 1px 6px rgba(33, 33, 33, 0.38);
    -ms-box-shadow: 0px 1px 6px rgba(33, 33, 33, 0.38);
  }
  &__body {
    padding: 15px;
    position: relative;
  }
  &__left {
    display: inline-block;
    position: relative;
    width: 30%;
    max-width: 260px;
    min-height: 470px;
    @media screen and (max-width: 578px) {
      display: none;
    }
    &::after {
      top: -60px;
      content: "";
      position: absolute;
      left: -50px;
      bottom: -60px;
      right: 0;
      background-color: #f5f5f5;
      border-radius: 0 50% 50% 0 ;
      @media screen and (max-width: 578px) {
        display: none;
      }
    }
    img {
      position: relative;
      z-index: 2;
      width: 150px;
      position: absolute;
      left: 45%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__right {
    display: inline-block;
    padding-left: 1rem;
    width: 70%;
    vertical-align: top;
    @media screen and (max-width: 578px) {
      width: 100%;
    }
    &--body {
      padding: 0 5px;
    }
  }

  &__heade {
    display: flex;
    padding: 10px;
  }

  &__title {
    flex: 1;
    font-size: 24px;
    font-weight: bold;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__button {
    background-color: #51d1c4;
    line-height: 25px;
    color: #fff;
    padding: 5px 25px;
    border-radius: 20px;
    display: inline-block;
  }
}

